import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import useSiteMetadata from '../hooks/use-site-config';
import { colors } from '../tokens';

const FooterWrapper = styled.footer`
  text-align: left;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;

  & nav {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    max-width: 900px;
    margin: 0 auto;

    .footer-col {
      flex: 1 auto;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding-right: 1em;
      font-size: 14px;
    }
  }

  & a {
    font-weight: bold;

    &:hover {
    }
  }

  .footer-col > p {
    margin: 0;
  }
  .footer-title {
    margin: 0 0 1rem;
  }

  .footer-item {
    padding: 0.25rem 0;
  }

  .footer-heart {
    color: ${colors.heartFooter};
  }

  .footer-item-text {
    padding: 0.1rem 0;
  }

  .footer-header {
    order: 1;
    margin: 0 0.25rem;
    margin-right: 0.25rem;
    padding: 0.25rem;
  }

  @media (max-width: 564px) {
    .footer-col:first-child {
      width: 100%;
    }
  }
`;

const Footer = () => {
  const { authorName, footerLinks } = useSiteMetadata();

  const FooterItem = ({ item }) => {
    if (item.url.startsWith('/')) {
      return (
        <span className="footer-item">
          <Link className="footer-link" to={item.url}>
            {item.label}
          </Link>
        </span>
      );
    }
    return (
      <span className="footer-item">
        <a className="footer-link" href={item.url}>
          {item.label}
        </a>
      </span>
    );
  };

  const FooterColumn = ({ column }) => (
    <div className="footer-col">
      {/* <h5 className="footer-title" key={column.sectionName}>
        {column.sectionName}
      </h5> */}
      {column.links.map((item) => <FooterItem item={item} key={`footer-column-item-${item.label}`} />)}
    </div>
  );

  return (
    <FooterWrapper>
      <nav>
        {footerLinks.map((column) => <FooterColumn column={column} key={`footer-column-${column.sectionName}`} />)}
        <div className="footer-col">
          <h5 className="footer-title">
            {authorName}
            {' '}
            ©
            {new Date().getFullYear()}
          </h5>
        </div>
      </nav>
    </FooterWrapper>
  );
};

export default Footer;
